import React, { useCallback } from 'react';
import Avatar from 'react-avatar';
import './style.scss';

import { useCreateChatRoom, useGetUserSearch } from '../../client-api';

const ChatUsersSelect = ({
  account,
  setOpenUsers,
  setCurrentRoom,
  setCreateNewRoomFlag,
}) => {
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const { data: userSearchData } = useGetUserSearch(!!account);
  const { mutateAsync: createNewChatRoom } = useCreateChatRoom();

  const checkUser = useCallback(
    (id) => {
      if (selectedUsers.find((userId) => userId === id)) {
        setSelectedUsers(selectedUsers.filter((userId) => userId !== id));
      } else {
        setSelectedUsers([...selectedUsers, id]);
      }
    },
    [selectedUsers]
  );

  const onCreateNewChatRoom = useCallback(async () => {
    const chatRoom = await createNewChatRoom(selectedUsers);
    if (chatRoom) {
      setCurrentRoom(chatRoom.chatRoomId);
      setOpenUsers(false);
      setCreateNewRoomFlag(false);
    }
  }, [
    createNewChatRoom,
    selectedUsers,
    setCreateNewRoomFlag,
    setCurrentRoom,
    setOpenUsers,
  ]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <div
        style={{
          width: '80%',
          height: '80%',
          maxWidth: '640px',
          overflowY: 'auto',
          zIndex: '100',
          backgroundColor: '#fff',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
        }}
      >
        <button
          style={{
            display: 'block',
            width: 40,
            height: 40,
            margin: 10,
            marginLeft: 'auto',
            background: 'transparent',
            fontSize: 24,
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setOpenUsers(false)}
        >
          ×
        </button>
        {userSearchData
          .filter((item) => account.id !== item.id)
          .map((user) => (
            <div key={user._id} className={`Chat-room`}>
              <div
                onClick={() => checkUser(user.id)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedUsers.find((id) => id === user.id)}
                  onChange={() => checkUser(user.id)}
                />
                <Avatar
                  name={user.name}
                  src={user.profileImg}
                  size={50}
                  round={true}
                />
                <p
                  style={{
                    fontSize: '1.1rem',
                    fontWeight: '700',
                    color: '#1E1F23',
                  }}
                >
                  {user.name}
                </p>
              </div>
            </div>
          ))}
        <button
          onClick={onCreateNewChatRoom}
          style={{
            display: 'flex',
            justifyContent: 'cetner',
            alignItems: 'center',
            margin: '10px auto',
            padding: '10px 20px',
            background: '#b6322e',
            color: '#ffffff',
            borderRadius: '10px',
          }}
        >
          DMを送る
        </button>
      </div>
    </div>
  );
};

export default ChatUsersSelect;
