import React from 'react';
import './style.scss';

const CreateGroupChatButton = ({ setOpenUsers }) => {
  return (
    <button
      onClick={() => setOpenUsers(true)}
      style={{
        width: '100%',
        height: 'fit-content',
        margin: 10,
        padding: 10,
        background: '#b6322e',
        color: '#ffffff',
        borderRadius: 8,
        cursor: 'pointer',
      }}
    >
      宛先
    </button>
  );
};

export default CreateGroupChatButton;
