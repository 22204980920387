import React, { useContext, useState } from 'react';
import { StoreContext } from '../../store/store';
import Loading from '../loading';
import { withRouter } from 'react-router-dom';
import {
  useGetChatRecentConversation,
  useGetChatRooms,
} from '../../client-api';
import './style.scss';
import ChatRooms from './ChatRooms';
import ChatMessages from './ChatMessages';
import ChatUsersSelect from './ChatUsersSelect';
import CreateGroupChatButton from './CreateGroupChatButton';
import { MediaQuery, useMediaQuery } from '../../hooks/useMediaQuery';
import { ICON_ARROWBACK } from "../../Icons";

const Chat = () => {
  const { state, actions } = useContext(StoreContext);
  const { account } = state;
  const [currentRoom, setCurrentRoom] = useState(null);
  const [createNewRoomFlag, setCreateNewRoomFlag] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const isSM = useMediaQuery(MediaQuery.sm);

  const queryEnabled = !!account;
  const {
    data: rooms,
    error: roomsError,
    isLoading: roomsLoading,
  } = useGetChatRooms(queryEnabled);
  const {
    data: recentConversations,
    error: recentConversationError,
    isLoading: recentChatLoading,
  } = useGetChatRecentConversation(queryEnabled);
  const dataLoading = !rooms || !recentConversations;

  const loading = dataLoading || roomsLoading || recentChatLoading;

  if (loading) return Loading();

  return (
    <div className="Chat-container">
      {isSM ? (
        <>
          <ChatRooms
            account={account}
            rooms={rooms}
            currentRoom={currentRoom}
            setCurrentRoom={setCurrentRoom}
            setCreateNewRoomFlag={setCreateNewRoomFlag}
          />
          {currentRoom && (
            <ChatMessages
              roomId={currentRoom}
              account={account}
              onBack={() => {
                setCurrentRoom(null);
              }}
              createNewRoomFlag={createNewRoomFlag}
            />
          )}
          {createNewRoomFlag && (
            <CreateGroupChatButton setOpenUsers={setOpenUsers} />
          )}
        </>
      ) : (
        <>
          {!currentRoom && !createNewRoomFlag && (
            <ChatRooms
              account={account}
              rooms={rooms}
              currentRoom={currentRoom}
              setCurrentRoom={setCurrentRoom}
              setCreateNewRoomFlag={setCreateNewRoomFlag}
            />
          )}
          {currentRoom && (
            <ChatMessages
              roomId={currentRoom}
              account={account}
              onBack={() => {
                setCurrentRoom(null);
              }}
              createNewRoomFlag={createNewRoomFlag}
            />
          )}
          {createNewRoomFlag && (
            <>
            <div
              onClick={() => setCreateNewRoomFlag(false)}
              style={{
                height: 'fit-content',
                marginTop: 20,
                marginLeft: 10,
                cursor: "pointer",
              }}
            >
              <ICON_ARROWBACK />
            </div>
              <CreateGroupChatButton setOpenUsers={setOpenUsers} />
            </>
          )}
        </>
      )}
      {openUsers &&
        <ChatUsersSelect
          account={account}
          setOpenUsers={setOpenUsers}
          setCurrentRoom={setCurrentRoom}
          setCreateNewRoomFlag={setCreateNewRoomFlag}
        />
      }
    </div>
  );
};

export default withRouter(Chat);
