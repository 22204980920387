import React, { useMemo, useState } from "react";
import "./style.scss";
import moment from "moment";
import Avatar from "react-avatar";
import ChatRoomSearch from "./ChatRoomSerach";
import ChatRoomTabs from "./ChatRoomTabs";
import { useCreateChatRoom, useGetUserSearch } from "../../client-api";
const ChatRooms = ({ rooms, currentRoom, setCurrentRoom, account, setCreateNewRoomFlag }) => {
  const [search, setSearch] = useState("");
  const [tabType, setTabType] = useState("all");
  const totalUnreadCount = rooms.reduce(
    (n, { unreadCount }) => n + unreadCount,
    0
  );

  const { data: userSearchData } = useGetUserSearch(!!account);
  const { mutateAsync: createNewChatRoom } = useCreateChatRoom();

  const onCreateNewChatRoom = async (userId) => {
    const chatRoom = await createNewChatRoom([userId]);
    if (chatRoom) {
      setCurrentRoom(chatRoom.chatRoomId);
    }
  };

  const filteredRooms = useMemo(() => {
    if (search) {
      return rooms.filter((room) =>
        room.userIds.some((user) =>
          user.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
    if (tabType === "unread") {
      return rooms.filter((room) => room.unreadCount > 0);
    }
    return rooms;
  }, [search, tabType, rooms]);

  const filteredUsers = useMemo(() => {
    if (search) {
      const roomUsers = rooms
        .flatMap((room) => room.userIds)
        .map((user) => user._id);
      return userSearchData.filter(
        (user) =>
          !roomUsers.includes(user._id) &&
          user._id !== account._id &&
          user.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  }, [search, userSearchData, rooms]);
  return (
    <div className="Chat-rooms-container" style={{position: 'relative'}}>
      <ChatRoomSearch search={search} setSearch={setSearch} />
      <ChatRoomTabs
        tabType={tabType}
        setTabType={setTabType}
        unreadCount={totalUnreadCount}
      />
      {filteredRooms.map((room) => {
        const activeClass = currentRoom === room._id ? "Chat-room-active" : "";
        const activeUsers = room.userIds.filter(
          (user) => user._id !== account._id
        );
        const lastMessageStr = room.lastMessage ?? "";
        const lastMessageText =
          lastMessageStr.length > 30
            ? `${lastMessageStr.slice(0, 20)}...`
            : lastMessageStr;
        const lastTime = moment(room.updatedAt).fromNow();

        return (
          <div
            key={room._id}
            className={`Chat-room ${activeClass}`}
            onClick={() => {
              setCreateNewRoomFlag(false);
              setCurrentRoom(room._id);
            }}
          >
            <Avatar
              name={activeUsers[0].name}
              src={activeUsers[0].profileImg}
              size={50}
              round={true}
            />
            <div>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "700",
                  color: "#1E1F23",
                }}
              >
                {activeUsers.map(user => user.name).join(',')}
              </p>
              <p
                style={{
                  fontSize: "1.0rem",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {lastMessageText} {lastTime}
              </p>
            </div>

            {room.unreadCount > 0 && (
              <div className="Chat-room-badge">{room.unreadCount}</div>
            )}
          </div>
        );
      })}
      {filteredUsers.map((user) => {
        return (
          <div key={user._id} className={`Chat-room`}>
            <div
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <Avatar
                name={user.name}
                src={user.profileImg}
                size={50}
                round={true}
              />
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "700",
                  color: "#1E1F23",
                }}
              >
                {user.name}
              </p>
            </div>
            <p
              onClick={() => {
                onCreateNewChatRoom(user._id);
              }}
              style={{
                display: "flex",
                flex: 1,
                fontSize: "0.8rem",
                fontWeight: "700",
                color: "#2B4773",
                cursor: "pointer",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textDecoration: "underline",
              }}
            >
              DMを送る
            </p>
          </div>
        );
      })}
      <button
        onClick={() => {
          setCurrentRoom(null);
          setCreateNewRoomFlag(true);
        }}
        style={{
          position: 'absolute',
          bottom: 20,
          right: 20,
          width: 40,
          height: 40,
          background: '#b6322e',
          color: '#ffffff',
          textAlign: "center",
          borderRadius: '50%',
          fontSize: 24,
          cursor: 'pointer',
        }}
      >
        +
      </button>
    </div>
  );
};
export default ChatRooms;
